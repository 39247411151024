<template>
  <div class='flex flex-row lg:flex-col w-full lg:w-64'>
    <div class='bg-gray-50 p-2 w-1/2 lg:w-full'>
      <img :src='showingContent.thumbnailUrl' class='h-48 w-60 object-cover' style='object-position: 50% 0%;'>
    </div>
    <div class='bg-white p-4 h-32 w-1/2 lg:w-full'>
      <div class='font-semibold mb-2'
        style='themeTextColorStyle'>
        {{ showingContent.title }}
      </div>
      <div class='text-sm'>
        <v-clamp 
          class='whitespace-pre-line'
          :max-lines='3'>
          {{ showingContent.description }}
        </v-clamp>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VClamp from 'vue-clamp'

export default {
  props: ['showingContent'],
  name: 'SponsorProductPreview',
  components: {
    VClamp
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
  },
}
</script>
