<template>
  <div class='text-sm lg:text-base text-center'>
    <div v-if='hasSelectedSponsorRequestInfoButton' class='mb-2'>
      <button v-if='!selectedSponsorInfoRequested'
              :style='requestInfoButtonStyle'
              class='w-full py-3 rounded uppercase text-white text-sm font-semibold hover:shadow-md'
              @click='clickBoothVisitorRequestInfoButton'>
        {{ selectedSponsorRequestInfoButtonText }}
      </button>
      <div v-else
           :style='requestInfoButtonStyle'
            class='w-full py-3 rounded uppercase text-white text-sm font-semibold opacity-50'>
        {{ selectedSponsorRequestInfoDoneText }}
      </div>
    </div>
    <div v-if='hasSelectedSponsorQuizButton' class='mb-2'>
      <button v-if='!selectedSponsorQuizCompleted'
              :style='quizButtonStyle'
              class='w-full py-3 rounded uppercase text-white text-sm font-semibold hover:shadow-md'
              @click='openSponsorQuiz'>
        Quiz Event
      </button>
      <div v-else
           :style='quizButtonStyle'
            class='w-full py-3 rounded uppercase text-white text-sm font-semibold opacity-50'>
        Quiz Completed
      </div>
      <sponsor-quiz />
    </div>
    <div v-if='hasSelectedSponsorStampButton'>
      <div v-if='!selectedSponsorStamped'>
        <button :style='stampButtonStyle'
                class='w-full py-3 rounded uppercase text-white text-sm font-semibold hover:shadow-md mb-2'
                :class='disabledStampButtonClass'
                :disabled='hasWaitingTimeBeforeStamp'
                @click='clickBoothVisitorStampButton'>
          {{ selectedSponsorStampButtonText }}
        </button>
        <div v-if='showingWaitingTimeBeforeStamp' class='text-xs text-gray-400' >
          {{ selectedSponsorWaitingMessageBeforeStamp }} <span class='text-gray-700'>{{showingWaitingTimeBeforeStamp}}</span> seconds
        </div>
      </div>
      <div v-else>
        <div :style='stampButtonStyle'
              class='w-full py-3 rounded uppercase text-white text-sm font-semibold opacity-50'>
          {{ selectedSponsorStampDoneText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SponsorQuiz from '@/components/sponsors/SponsorQuiz.vue'
import {mapState, mapGetters, mapActions} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'SponsorVisitActions',
  components: {
    SponsorQuiz
  },
  data () {
    return {
      showingWaitingTimeBeforeStamp: 0
    }
  },
  watch: {
    selectedSponsorWaitingTimeBeforeStamp: {
      handler: function (newVal) {
        if (newVal) {
          this.showingWaitingTimeBeforeStamp = cloneDeep(this.selectedSponsorWaitingTimeBeforeStamp)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('sponsors', [
      'selectedSponsor'
    ]),
    ...mapGetters('sponsors', [
      'selectedSponsorStamped',
      'selectedSponsorInfoRequested',
      'selectedSponsorQuizCompleted',
      'hasSelectedSponsorStampButton',
      'hasSelectedSponsorRequestInfoButton',
      'selectedSponsorStampButtonText',
      'selectedSponsorRequestInfoButtonText',
      'selectedSponsorStampButtonColor',
      'selectedSponsorRequestInfoButtonColor',
      'selectedSponsorStampModalText',
      'selectedSponsorRequestInfoModalText',
      'selectedSponsorStampModalConfirmButtonText',
      'selectedSponsorRequestInfoModalConfirmButtonText',
      'selectedSponsorStampDoneText',
      'selectedSponsorRequestInfoDoneText',
      'selectedSponsorWaitingTimeBeforeStamp',
      'selectedSponsorWaitingMessageBeforeStamp',
      'hasSelectedSponsorQuizButton',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    requestInfoButtonStyle () {
      return `background-color: ${this.selectedSponsorRequestInfoButtonColor};`
    },
    stampButtonStyle () {
      return (this.selectedSponsorStampButtonColor) ? `background-color: ${this.selectedSponsorStampButtonColor};` : `background-color: ${this.eventMainThemeColor};`
    },
    quizButtonStyle () {
      return (this.selectedSponsorStampButtonColor) ? `background-color: ${this.selectedSponsorStampButtonColor};` : `background-color: ${this.eventMainThemeColor};`
    },
    hasWaitingTimeBeforeStamp () {
      return this.showingWaitingTimeBeforeStamp > 0
    },
    disabledStampButtonClass () {
      return (this.hasWaitingTimeBeforeStamp) ? 'opacity-50 cursor-default hover:shadow-none' : ''
    },
  },
  methods: {
    ...mapActions('sponsors', [
      'postBoothVisitor',
      'openSponsorQuiz'
    ]),
    clickBoothVisitorStampButton () {
      this.$confirm(`${this.selectedSponsorStampModalText}`, {
        confirmButtonText: `${this.selectedSponsorStampModalConfirmButtonText}`,
        cancelButtonText: '취소',
        type: 'success'
      }).then(() => {
        const boothVisitorParams = {
          sponsorId: this.$route.query.sponsor_id,
          actionType: 'stamp'
        }
        this.postBoothVisitor(boothVisitorParams).then(() => {
          this.$message({
            type: 'success',
            message: `${this.selectedSponsorStampDoneText}`
          })
        })
      }).catch(() => {})
    },
    clickBoothVisitorRequestInfoButton () {
      this.$confirm(`${this.selectedSponsorRequestInfoModalText}`, {
        confirmButtonText: `${this.selectedSponsorRequestInfoModalConfirmButtonText}`,
        cancelButtonText: '취소',
        type: 'success'
      }).then(() => {
        const boothVisitorParams = {
          sponsorId: this.$route.query.sponsor_id,
          actionType: 'request_info'
        }
        this.postBoothVisitor(boothVisitorParams).then(() => {
          this.$message({
            type: 'success',
            message: `${this.selectedSponsorRequestInfoDoneText}`
          })
        })
      }).catch(() => {})
    }
  },
  beforeDestroy () {
    clearInterval(this.decreaseWaitingTimeBeforeStamp)
  },
  mounted () {
    this.decreaseWaitingTimeBeforeStamp = setInterval(() => {
      if (this.showingWaitingTimeBeforeStamp > 0) {
        this.showingWaitingTimeBeforeStamp -= 1
      }
    }, 1000)
  }
}
</script>
