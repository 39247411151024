<template>
  <el-dialog
    :visible='showSponsorContentDetail'
    :before-close='closeDialog'
    :fullscreen='dialogFullscreenStatus'
    :width='dialogWidth'
    :top='dialogTopMargin'
    class='product-detail-dialog'>
    <component :is='contentComponentByContentType(selectedSponsorContentDetail)'
               :showing-content='selectedSponsorContentDetail'
               :autoplay='false'
               :mute='false' />
    <div class='flex-grow bg-white p-8'>
      <div class='text-xl text-blue-300 font-semibold mb-2'>
        {{ selectedSponsorContentDetail.title }}
      </div>
      <div class='text-base'>
        {{ selectedSponsorContentDetail.description }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapActions }  from 'vuex'
import VodContentVimeo           from '@/components/contents/VodContentVimeo.vue'
import ImageContent              from '@/components/contents/ImageContent.vue'

export default {
  name: 'SponsorProductDetail',
  components: {
    VodContentVimeo,
    ImageContent,
  },
  computed: {
    ...mapState('sponsors',[
      'showSponsorContentDetail',
      'selectedSponsorContentDetail',
    ]),
    dialogFullscreenStatus () {
      return (window.innerWidth < 1024) 
    },
    dialogTopMargin () {
      return this.dialogFullscreenStatus ? '' : '5vh'
    },
    dialogWidth () {
      return this.dialogFullscreenStatus ? '' : '70%'
    },
  },
  methods: {
    ...mapActions('sponsors', [
      'closeSponsorContentDetail',
      'resetSelectedSponsorContentDetail',
    ]),
    contentComponentByContentType (content) {
      if (content.format) {
        if (content.format === 'video') {
          return 'vod-content-vimeo'
        } else if (content.format === 'image') {
          return 'image-content'
        } else {
          console.error('unknown content format: ', content.format)
          return ''
        }
      } else {
        return ''
      }
    },
    closeDialog () {
      this.resetSelectedSponsorContentDetail()
      this.closeSponsorContentDetail()
    }
  },
}
</script>

<style type='scss'>
  .product-detail-dialog .el-dialog__body {
    @apply px-0 py-4;
  }

  .product-detail-dialog .el-dialog__headerbtn {
    @apply top-3;
  }

</style>
