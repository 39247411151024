<template>
  <img :src='selectedContentSourceUrl'>
</template>

<script>
import isEmpty  from 'lodash/isEmpty'
export default {
  props: ['showingContent'],
  name: 'ImageContent',
  computed: {
    hasShowingContent () {
      return !isEmpty(this.showingContent)
    },
    selectedContentSourceUrl () {
      if (this.hasShowingContent) {
        return this.showingContent.data.sourceUrl
      } else {
        return ''
      }
    }
  }
}
</script>
