<template>
  <div class='flex flex-col gap-y-2'>
    <div class='h-32 flex flex-col justify-center items-start'>
      <img :src='selectedSponsor.logoImageUrl' class='max-h-24'>
    </div>
    <div class='flex flex-row justify-start'>
      <a v-if='selectedSponsor.siteUrl'
        :href='selectedSponsor.siteUrl' 
        target='_blank' 
        class='py-1 px-2 hover:bg-gray-200 rounded-md'>
        <world-icon class='h-8'/>
      </a>
      <a v-if='selectedSponsor.youtubeUrl'
        :href='selectedSponsor.youtubeUrl' 
        target='_blank' 
        class='py-1 px-2 hover:bg-gray-200 rounded-md'>
        <brand-youtube-icon class='h-8'/>
      </a>
      <a v-if='selectedSponsor.facebookUrl'
        :href='selectedSponsor.facebookUrl' 
        target='_blank' 
        class='py-1 px-2 hover:bg-gray-200 rounded-md'>
        <brand-facebook-icon class='h-8'/>
      </a>
      <a v-if='selectedSponsor.instagramUrl'
        :href='selectedSponsor.instagramUrl' 
        target='_blank' 
        class='py-1 px-2 hover:bg-gray-200 rounded-md'>
        <brand-instagram-icon class='h-8'/>
      </a>

      <el-popover v-if='selectedSponsor.phoneNumber'
        placement='bottom'
        trigger='click'
        :content='selectedSponsor.phoneNumber'>
        <button
          slot='reference'
          class='py-1 px-2 hover:bg-gray-200 rounded-md'>
          <phone-icon class='h-8'/>
        </button>
      </el-popover>
      <el-popover v-if='selectedSponsor.email'
        placement='bottom'
        trigger='click'
        :content='selectedSponsor.email'>
        <button
          slot='reference'
          class='py-1 px-2 hover:bg-gray-200 rounded-md'>
          <mail-icon class='h-8'/>
        </button>
      </el-popover>
    </div>
    <div class='whitespace-pre-line text-gray-700 text-sm'>{{ selectedSponsor.intro }}</div>
    <sponsor-visit-actions />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BrandInstagramIcon, BrandFacebookIcon, BrandYoutubeIcon, WorldIcon, PhoneIcon, MailIcon }  from 'vue-tabler-icons'
import SponsorVisitActions from '@/components/sponsors/SponsorVisitActions.vue'

export default {
  name: 'SponsorDetails',
  components: {
    SponsorVisitActions,
    BrandInstagramIcon,
    BrandFacebookIcon,
    BrandYoutubeIcon,
    WorldIcon,
    PhoneIcon,
    MailIcon,
  },
  computed: {
    ...mapState('sponsors', [
      'selectedSponsor'
    ]),
  },
}
</script>
