<template>
  <div class='px-2 lg:px-0'>
    <div v-if='hasBannerImage'
      class='text-center relative'>
      <content-background-cover
        :background-color='bannerBackgroundColor'
        :top-offset='0'
        class='z-0' />
      <img
        :src='selectedSponsor.topMainImageUrl'
        class='mx-auto hidden lg:block'
        :style='`object-fit: cover;height: 100%; max-height: ${bannerHeight}; object-position: left top;`' />
    </div>
    <button @click='goBack'
      class='static lg:absolute lg:top-2 lg:left-0 mt-2 lg:mt-0 pl-1 pr-2 hover:pl-0 rounded text-gray-900 border border-transparent hover:border-gray-800 flex flex-row justify-center items-center text-normal'>
      <arrow-narrow-left-icon class='h-4' />
      See All Sponsors
    </button>
    <div class='pt-2 lg:py-12 flex flex-col lg:flex-row gap-y-8 lg:gap-x-16 mb-12 lg:mb-36 relative'>
      <sponsor-details class='w-full lg:w-1/3' />
      <div class='w-full lg:w-2/3'>
        <component :is='contentComponentByContentType(selectedSponsorMainContent)'
          :showing-content='selectedSponsorMainContent'
          :autoplay='true'
          :mute='true'
          :loop='loopVideo'
          class='w-full h-auto object-cover' />
      </div>
      <content-background-cover
        background-color='#FAFAFA'
        :top-offset='0'
        class='z-0' />
    </div>
    <div v-if='hasSelectedSponsorOtherContents'
      class='flex flex-col lg:flex-row gap-y-8 relative' 
      :class='narrowClass'>
      <div class='sponsor-product-preview-title h-12 lg:h-96 flex flex-col justify-center items-center'
        :style='`background-color: ${eventMainThemeColor};`'>
        <div class='font-semibold text-white text-xl lg:text-2xl'>
          {{ selectedSponsorProductInformationTitle }}
        </div>
      </div>
      <div class='sponsor-product-preview-list-box lg:absolute flex flex-col lg:flex-row gap-y-4 lg:gap-x-8 lg:overflow-x-auto h-full'>
        <div v-for='(otherContent, index) in selectedSponsorOtherContents'
          :key='`otherContent-${index}`'
          class ='flex flex-col my-auto border cursor-pointer relative'
          @click='openDetail(otherContent)'>
          <div class='hidden lg:flex flex-col justify-center items-center absolute top-2 left-2 bg-gray-500 opacity-50 hover:opacity-0 h-48 w-60'>
            <zoom-in-icon size='3x' class='text-white font-bold' />
          </div>
          <sponsor-product-preview
            :showing-content='otherContent' />
        </div>
      </div>
    </div>
    <sponsor-product-detail />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import ContentBackgroundCover             from '@/components/ContentBackgroundCover.vue'
import VodContentVimeo                    from '@/components/contents/VodContentVimeo.vue'
import ImageContent                       from '@/components/contents/ImageContent.vue'
import SponsorDetails                     from '@/components/sponsors/SponsorDetails.vue'
import SponsorProductPreview              from '@/components/sponsors/SponsorProductPreview.vue'
import SponsorProductDetail               from '@/components/sponsors/SponsorProductDetail.vue'
import { ZoomInIcon }                     from "@vue-hero-icons/outline"
import { ArrowNarrowLeftIcon }            from 'vue-tabler-icons'

export default {
  name: 'Sponsor',
  components: {
    ContentBackgroundCover,
    VodContentVimeo,
    ImageContent,
    SponsorDetails,
    SponsorProductPreview,
    SponsorProductDetail,
    ZoomInIcon,
    ArrowNarrowLeftIcon,
  },
  props: {
    loopVideo: {
      type: Boolean,
      default: false,
    },
    isNarrow: {
      type: Boolean,
      default: false,
    },
    bannerMaxHeight: {
      type: String,
      default: '100px',
    },
    requireLogin: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    ...mapState('sponsors', [
      'selectedSponsor',
      'showSponsorContentDetail'
    ]),
    ...mapGetters('sponsors', [
      'selectedSponsorMainContent',
      'selectedSponsorOtherContents',
      'selectedSponsorProductInformationTitle',
      'hasSelectedSponsorOtherContents'
    ]),
    hasBannerImage () {
      return this.selectedSponsor.topMainImageUrl
    },
    bannerBackgroundColor () {
      return (this.selectedSponsor.topMainImageBgColor) ? this.selectedSponsor.topMainImageBgColor : '#EEEEEE'
    },
    bannerHeight () {
      return (this.bannerMaxHeight) ? this.bannerMaxHeight : '100px'
    },
    narrowClass () {
      return (this.isNarrow) ? 'narrow' : ''
    },
  },
  methods: {
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('sponsors', [
      'getSingleSponsor',
      'getSingleSponsorNoAuth',
      'openSponsorContentDetail',
      'selecteSponsorContentDetail'
    ]),
    contentComponentByContentType (content) {
      if (content) {
        if (content.format === 'video') {
          return 'vod-content-vimeo'
        } else if (content.format === 'image') {
          return 'image-content'
        }
      } else {
        return ''
      }
    },
    openDetail (content) {
      if (content.format === 'file' || content.format === 'link') {
        window.open(content.data.sourceUrl, '_blank')
      } else {
        this.selecteSponsorContentDetail(content)
        this.openSponsorContentDetail()
      }
    },
    goBack () {
      this.$router.go(-1)
    },
  },
  beforeDestroy () {
    this.hideFullpageLoadingIndicator()
  },
  mounted () {
    this.showFullpageLoadingIndicator()
    if (this.requireLogin) {
      this.getSingleSponsor(this.$route.query.sponsor_id).then(() => {
      this.hideFullpageLoadingIndicator()
    })
    } else {
      this.getSingleSponsorNoAuth(this.$route.query.sponsor_id).then(() => {
      this.hideFullpageLoadingIndicator()
    })
    }
    
  },
}
</script>

<style lang='scss'>
  .sponsor-product-preview-list-box {
    left: 0%;
    width: 100%;
  }

  .sponsor-product-preview-title {
    @apply w-full;
  }

  @media (min-width: 1024px) {
    .sponsor-product-preview-list-box {
      left: 30%;
      width: 70%;
    }

    .sponsor-product-preview-title {
      @apply w-1/3;
    }

    .narrow  .sponsor-product-preview-title {
      @apply w-1/4;
    }

    .narrow  .sponsor-product-preview-list-box {
      left: 35%;
      width: 65%;
    }

  }
</style>
