<template>
  <el-dialog
    custom-class='sponsor-quiz-dialog'
    :visible='showSponsorQuiz'
    :before-close='closeSponsorQuiz'
    :fullscreen='dialogFullscreenStatus'
    :append-to-body='true'
    :top='dialogTopMargin'
    :width='dialogWidth'>
    <div class='p-2 lg:pt-4 lg:px-8 lg:pb-12'>
      <h1 class='text-2xl text-gray-900 mb-8 lg:mb-16'>Quiz Event: {{selectedSponsor.name}}</h1>
      <div class='flex flex-col lg:flex-row justify-between items-stretch gap-y-4 lg:gap-y-0 lg:gap-x-8'>
        <div class='flex-grow p-4'
          style='background-color: #FAFAFA;'>
          <img
            :src='selectedSponsor.logoImageUrl'
            class='mb-8'
            style='max-height: 4rem;' />
          <h2 class='font-bold text-gray-800 text-lg uppercase'>Question</h2>
          <p
            class='text-gray-600 text-normal leading-6 whitespace-pre-line'
            style='word-break: keep-all;'>
            {{selectedSponsorQuizQuestionText}}
          </p>
        </div>
        <div class='w-full lg:w-1/3 flex-shrink-0 flex flex-col justify-between'>
          <div class='flex-grow'>
            <label v-for='(answer, index) in nonEmptyQuestions'
              :key='`answer-${answer}-${index}`'
              class='border block p-2 py-3'
              :class='isSelectedAnswer(answer)'>
              <input type='radio'
                :id='`${answer}-${index}`'
                v-model='selectedQuizAnswer'
                :value='answer'>
              <span :for='`${answer}-${index}`' class='p-2'>{{answer}}</span>
            </label>
          </div>
          <button
            class='quiz-submit-button border w-full py-3 mt-2 rounded-md hover:shadow-md opacity-80 hover:opacity-100 text-white uppercase'
            :style='buttonBackgroundColor'
            @click='submitBoothVisitorQuizAnswer'
            :disabled='disabledQuizSubmitButton'>
            submit
          </button>
        </div>
      </div>

    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'SponsorQuiz',
  data () {
    return {
      selectedQuizAnswer: '',
    }
  },
  computed: {
    ...mapState('sponsors', [
      'showSponsorQuiz',
      'selectedSponsor',
    ]),
    ...mapGetters('sponsors', [
      'selectedSponsorQuizQuestionText',
      'selectedSponsorQuizAnswers',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    nonEmptyQuestions () {
      return this.selectedSponsorQuizAnswers.filter(answer => answer)
    },
    dialogFullscreenStatus () {
      return (window.innerWidth < 1024)
    },
    buttonBackgroundColor () {
      return (this.eventMainThemeColor) ? `background-color: ${this.eventMainThemeColor}` : 'background-color: #343434'
    },
    dialogTopMargin () {
      return this.dialogFullscreenStatus ? '' : '5vh'
    },
    dialogWidth () {
      return this.dialogFullscreenStatus ? '' : '70%'
    },
    disabledQuizSubmitButton () {
      return !this.selectedQuizAnswer
    }
  },
  methods: {
    ...mapActions('sponsors', [
      'closeSponsorQuiz',
      'postBoothVisitor'
    ]),
    isSelectedAnswer (answer) {
      return (this.selectedQuizAnswer === answer) ? 'border-blue-400 bg-blue-100' : 'border-gray-100'
    },
    submitBoothVisitorQuizAnswer () {
      this.$confirm('Submit your quiz answer?', {
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        const boothVisitorParams = {
          sponsorId: this.$route.query.sponsor_id,
          actionType: 'quiz',
          quizAnswer: this.selectedQuizAnswer
        }
        this.postBoothVisitor(boothVisitorParams).then(() => {
          this.closeSponsorQuiz()
          this.$message({
            type: 'success',
            message: 'quiz submitted complete'
          })
        })
      }).catch(() => {})
    }
  },
}
</script>

<style type='scss'>
  .sponsor-quiz-dialog .el-dialog__body {
    @apply px-0 py-4;
  }

  .el-dialog.sponsor-quiz-dialog {
    max-width: 800px;
  }

  .sponsor-quiz-dialog .el-dialog__headerbtn {
    @apply top-3;
  }

   .sponsor-quiz-dialog .quiz-submit-button:disabled {
      @apply cursor-not-allowed;
      opacity: 0.2;
   }

</style>
